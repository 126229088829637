import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { transparentize, readableColor } from 'polished'
import styled from 'styled-components'
import { config, useSpring, animated } from 'react-spring'
import Layout from '../components/layout'
import Copyright from '../components/Copyright'
import { Box, AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import { useSiteMetadata } from '../utils'

const PBox = styled(AnimatedBox)`
  max-width: 1400px;
  margin: 0 auto;
`

const TwoColumn = styled(Box)`
  column-count: 2;

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    column-count: 1;
  }
`

const Content = styled(Box)<{ bg: string }>`
  background-color: ${props => transparentize(0.9, props.bg)};

  .gatsby-image-wrapper:not(:last-child) {
    margin-bottom: ${props => props.theme.space[10]};

    @media (max-width: ${props => props.theme.breakpoints[3]}) {
      margin-bottom: ${props => props.theme.space[8]};
    }
  }
`

const Quote = styled.blockquote`
  &:before {
    font-size: ${props => props.theme.fontSizes[5]};
    display: block;
    content: open-quote;
    height: 1px;
    margin-left: -0.55em;
    position:relative;
    top:-7px;
  }

  > footer {
    /* font-style: italic; */
    color: ${props => props.theme.colors.grey};
    font-size: ${props => props.theme.fontSizes[0]};
  }
`

const Description = styled(animated.div)`
  max-width: 960px;
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  line-height: 1.58;
`

type PageProps = {
  data: {
    project: {
      title_detail: string
      color: string
      desc: string
      quote: string
      quote_author: string
      slug: string
      parent: {
        modifiedTime: string
        birthTime: string
      }
      cover_desktop: {
        childImageSharp: {
          resize: {
            src: string
          }
        }
      }
    }
    images: {
      nodes: {
        name: string
        childImageSharp: {
          fluid: {
            aspectRatio: number
            src: string
            srcSet: string
            sizes: string
            base64: string
            tracedSVG: string
            srcWebp: string
            srcSetWebp: string
          }
        }
      }[]
    }
  }
}

const Project: React.FunctionComponent<PageProps> = ({ data: { project, images } }) => {
  const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })
  const descAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })
  const imagesAnimation = useSpring({ config: config.slow, delay: 800, from: { opacity: 0 }, to: { opacity: 1 } })

  const site = useSiteMetadata();

  return (
    <Layout color={project.color} FooterComponent={Copyright}>
      <SEO
        pathname={project.slug}
        title={project.title_detail}
        desc={project.desc}
        node={project.parent}
        banner={project.cover_desktop.childImageSharp.resize.src}
        individual
      />
      <PBox py={10} px={[6, 6, 8, 10]}>
        <animated.h1 style={titleAnimation}>{project.title_detail}</animated.h1>
        {project.quote && (
          <Description style={descAnimation}>
            <Quote>
              {project.quote}
              <footer>{project.quote_author}</footer>
            </Quote>
          </Description>
        )}
      </PBox>
      <Content bg={project.color} py={10}>
        <PBox style={imagesAnimation} px={[6, 6, 8, 10]}>
          <TwoColumn>
            {images.nodes.map(image => (
              <Img
                style={{breakInside: 'avoid-column'}}
                alt={image.name}
                key={image.childImageSharp.fluid.src}
                fluid={image.childImageSharp.fluid}
              />
            ))}
          </TwoColumn>
        </PBox>
      </Content>
    </Layout>
  )
}

export default Project

export const query = graphql`
  query ProjectTemplate($slug: String!, $images: String!) {
    project: projectsYaml(slug: { eq: $slug }) {
      title_detail
      color
      desc
      quote
      quote_author
      slug
      parent {
        ... on File {
          modifiedTime
          birthTime
        }
      }
      cover_desktop{
        childImageSharp {
          resize(width: 1200, height: 675, quality: 80) {
            src
          }
        }
      }
    }
    images: allFile(filter: { relativePath: { regex: $images } }, sort: { fields: name, order: ASC }) {
      nodes {
        name
        childImageSharp {
          fluid(quality: 95, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
